<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">공지 등록</h2>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 428px" />
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <tr>
              <th>구분<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Radio
                  :name="radioTypeData.name"
                  :dataList="radioTypeData.dataList"
                  :selectedId.sync="radioTypeData.selectedId"
                />
              </td>
              <th>상단 고정<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Radio
                  :name="radioFixYNData.name"
                  :dataList="radioFixYNData.dataList"
                  :selectedId.sync="radioFixYNData.selectedId"
                />
              </td>
            </tr>
            <tr>
              <th>공지 제목<span class="ico_purchase ico_star">필수항목</span></th>
              <td colspan="3">
                <Input
                  placeholder="제목 또는 등록자를 입력하세요."
                  :value.sync="title"
                  :maxLength="250"
                />
              </td>
            </tr>
            <tr>
              <th>공지 내용<span class="ico_purchase ico_star">필수항목</span></th>
              <td colspan="3">
                <div class="editor_comm">
                  <form method="">
                    <textarea id="summernote" name="editordata" />
                  </form>
                </div>
              </td>
            </tr>
            <tr>
              <th>첨부 파일</th>
              <td colspan="3">
                <FileWrite
                  :dataList="attachFileList"
                  @fileUploaded="fileUploaded"
                  @fileRemoved="fileRemoved"
                />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button type="submit" class="btn_primary btn_large" @click="onClickConfirm">저장</button>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableView from "@/components/shared/tableView/TableView";
import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";
import FileWrite from "@/components/common/file/FileWrite";
import Sticky from "@/components/layout/content/Sticky.vue";

import PageMixin from "@/mixins/PageMixin";

import ApiService from "@/services/ApiService";
import { decodeHTMLEntities } from "@/utils/stringUtils";
import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "NoticeWrite",
  components: {
    PageWithLayout,
    TableView,
    Radio,
    Input,
    FileWrite,
    Sticky,
  },
  mixins: [PageMixin],
  data() {
    return {
      isModifyMode: false,
      radioTypeData: {
        name: "noticeTypeRadio",
        dataList: [
          { id: "D", text: "일반" },
          { id: "M", text: "메인 공지" },
        ],
        selectedId: "D",
      },
      radioFixYNData: {
        name: "noticeFixedRadio",
        dataList: [
          { id: "N", text: "미 고정" },
          { id: "Y", text: "고정" },
        ],
        selectedId: "N",
      },
      title: "",
      // currentRadiodateIdSync:'0',
      textNormal: {
        text: "",
      },
      seq: null,
      attachFileList: [],
    };
  },
  beforeMount() {
    this.seq = this.$route.params.id;
  },
  beforeDestroy() {
    const summernote = $("#summernote");
    if (summernote) summernote.summernote("destroy");
  },
  mounted() {
    this.createSummernote({
      height: 310,
    });

    this.isModifyMode = this.$route.meta.isModifyMode;
    if (!this.isModifyMode) return;

    this.getItem(this.seq);
  },

  methods: {
    async getItem(seq) {
      const result = await ApiService.shared.getData(`${this.$apiPath.NOTICE}/${seq}`);
      if (!result.data) return;

      this.title = decodeHTMLEntities(document, result.data.title);
      this.radioTypeData.selectedId = result.data.noticeType;
      this.radioFixYNData.selectedId = result.data.fixYn;

      // $('#summernote').summernote('code', result.data.contents);

      const note = result.data.contents;
      const noteDecoded = decodeHTMLEntities(document, note);
      $("#summernote").summernote("code", noteDecoded);

      this.attachFileList = result.data.attachFileList || [];
    },
    fileUploaded(files) {
      this.attachFileList = this.attachFileList.concat(files);
    },
    fileRemoved(itemToRemove) {
      this.attachFileList = this.attachFileList.filter((item) => item !== itemToRemove);
    },
    onClickGoToList() {
      this.goToList();
    },
    goToList() {
      this.$router.push({
        path: this.$routerPath.SYSTEM_NOTICE_LIST,
      });
    },
    getAlertMessage() {
      if (!this.title.trim()) return "공지 제목을 입력해주세요.";

      var markupStr = $("#summernote").summernote("code");
      // const cleanText = markupStr.replace(/<\/?[^>]+(>|$)/g, "");

      // if( !cleanText )  return '공지 내용을 입력해주세요.';

      if (markupStr === "<p><br></p>") {
        return "공지 내용을 입력해주세요.";
      }

      return null;
    },
    onClickConfirm() {
      const alertMessage = this.getAlertMessage();

      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      var markupStr = $("#summernote").summernote("code");

      const userData = LocalStorageManager.shared.getUserData();

      const obj = {
        noticeType: this.radioTypeData.selectedId,
        fixYn: this.radioFixYNData.selectedId,
        title: this.title,
        contents: markupStr,
        //managerNum: userData.managerNum,
        attachFileList: this.attachFileList,
      };

      this.saveData(obj);
    },
    async saveData(obj) {
      var result;

      if (this.isModifyMode) {
        result = await ApiService.shared.putData(`${this.$apiPath.NOTICE}/${this.seq}`, obj);
      } else {
        result = await ApiService.shared.postData(this.$apiPath.NOTICE, obj);
      }

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      const originSeq = this.seq ? this.seq : result.data.seq;

      const path = `${this.$routerPath.SYSTEM_NOTICE_VIEW}/${originSeq}`;
      await this.$router.push({
        path: path,
      });
      //this.goToList();
    },
  },
};
</script>
